<template>
  <section>
    <div class="moment" v-show="pageDetailHide" v-if="refreshStatus">
      <edp-header-search
        class="bf headers"
        :headerLeftWord="$t('moment.momentHeader')"
        :searchPlaceHolder="$t('member.searchPlaceHolder')"
        @search="searchFn"
      ></edp-header-search>
      <div class="top-tabs bf">
        <div
          class="tabs-item f18"
          v-for="(item, i) in topList"
          :key="i"
          :class="{ active: topIndex == i }"
          @click="onTop(i)"
        >
          {{ item.name }}
          <el-tooltip
            v-if="lang == 'en'"
            class="item"
            effect="dark"
            placement="right"
            popper-class="draw_share_atooltip"
          >
            <div slot="content">{{ i == 0 ? "Quarter" : "Current Month" }}</div>
            <img src="@/src/assets/member/tips.png" alt="" class="tips"
          /></el-tooltip>
        </div>
      </div>
      <div class="topTips fac">
        <img src="@/src/assets/member/notice.png" alt="" class="tip" />
        <span class="f14 op5"> {{ $t("member.dataRange2") }}</span>
      </div>
      <div class="edp-common-content moment-content pot">
        <div class="tab-wrap mb16" v-if="topIndex == 4">
          <div
            class="tab-item fjc"
            :class="{ active: tabIndex == 0 }"
            @click="onTab(0)"
          >
            {{ $t("moment.5thany") }}
          </div>
          <div
            class="tab-item fjc"
            :class="{ active: tabIndex == 1 }"
            @click="onTab(1)"
          >
            {{ $t("moment.10thany") }}
          </div>
          <div
            class="tab-item fjc"
            :class="{ active: tabIndex == 2 }"
            @click="onTab(2)"
          >
            {{ $t("moment.15thany") }}
          </div>
        </div>
        <div
          v-infinite-scroll="load"
          :infinite-scroll-delay="100"
          :infinite-scroll-distance="10"
          :infinite-scroll-disabled="disabled"
          style="overflow: auto; height: 74vh"
        >
          <div class="member-wrap">
            <div
              class="member-item"
              v-for="(item, i) in pageList"
              :key="i"
              @click="onDetail(item.cdsid)"
            >
              <img :src="item.photo || nullImg" alt="" class="item-pic" />
              <div class="item-text">
                <div class="text-one">
                  <span class="name-text txt-elps">{{
                    utils.formatLang(lang, item.cname, item.ename)
                  }}</span>

                  <em v-if="item.empGenerations" class="lable"
                    ><span>{{ item.empGenerations }}</span></em
                  >
                </div>
                <!-- <div class="text-two">
                  <span class="tag">{{ item.gender }}</span
                  ><span class="tag"
                    >{{ item.age }}{{ $t("member.yearsold") }}</span
                  ><span class="tag">{{ item.baseAddress }}</span
                  ><span class="tag">{{ item.educationBackground }}</span> -->
                <!-- <span class="tag" v-if="item.childless">{{
                $t("member.Havechild")
              }}</span
              ><span class="tag" v-else>{{ $t("member.Childless") }}</span> -->
                <!-- </div> -->
                <div class="f14 mb8" v-if="topIndex == 0">
                  {{ item.dateTime }} {{ $t("moment.Promotedto") }}<br />
                  <strong class="cg">{{ item.jobPosition }}</strong>
                </div>
                <!-- <div class="f14 mb4" v-if="topIndex == 2">
                {{ item.dateTime }}
                <strong>{{ $t("moment.birthday") }}</strong>
              </div> -->
                <div class="f14 mb8" v-if="topIndex == 3">
                  {{ item.dateTime }}<br />
                  <strong class="cg">{{ $t("moment.Onboard") }}</strong>
                </div>
                <template v-if="topIndex == 4">
                  <div class="f14 mb8" v-if="tabIndex == 0">
                    {{ item.dateTime }}<br />
                    <strong class="cg">{{ $t("moment.5thworkany") }}</strong>
                  </div>
                  <div class="f14 mb8" v-if="tabIndex == 1">
                    {{ item.dateTime }}<br />
                    <strong class="cg">{{ $t("moment.10thworkany") }}</strong>
                  </div>
                  <div class="f14 mb8" v-if="tabIndex == 2">
                    {{ item.dateTime }}<br />
                    <strong class="cg">{{ $t("moment.15thworkany") }}</strong>
                  </div>
                </template>
                <div class="text-thr">
                  <div class="text-item txt-elps">
                    <em>{{ $t("member.Department") }}</em>
                    <span
                      ><span>{{
                        utils.formatLang(lang, item.dept, item.deptEname)
                      }}</span></span
                    >
                  </div>
                </div>
                <!-- <div class="text-thr" v-if="item.subdivision">
                <div class="text-item">
                  <em>{{ $t("member.Sub-department") }}</em>
                  <span
                    ><span>{{ item.subdivision }}</span></span
                  >
                </div>
              </div> -->
                <div class="text-thr">
                  <div class="text-item">
                    <em>{{ $t("member.Leader") }}</em>
                    <span class="txt-elps"
                      ><span>{{
                        utils.formatLang(
                          lang,
                          item.directLeaderChineseName,
                          item.directLeaderEnName
                        )
                      }}</span></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p
            v-if="noMore && total > 0 && !loading"
            class="end-wrap tac f16 pt10"
          >
            <span class="line"></span>
            <span class="txt">{{ $t("member.end") }}</span
            ><span class="line"></span>
          </p>
          <div v-if="total === 0" class="user-empty tac">
            <img src="@/src/assets/member/user-empty.png" alt="" class="mb16" />
            <p class="f14 tac">{{ $t("member.NoResults") }}</p>
          </div>
        </div>
        <p v-if="loading" class="tac pt10">
          <van-loading
            type="spinner"
            size="24px"
            color="#464950"
            text-color="#464950"
            >{{ $t("member.loading") }}</van-loading
          >
        </p>
      </div>
    </div>

    <router-view v-if="!pageDetailHide" />
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      tabIndex: 0,
      sorted: false,
      total: 0,
      totalPage: 0,
      query: {
        current: 1,
        size: 20,
        keywords: "",
      },
      pageList: [],
      loading: false,
      lastDept: "",
      nullImg: require("@/src/assets/common/default.png"),
      topList: [
        { name: this.$t("moment.Quarter"), index: 0 },
        { name: this.$t("moment.monthreturn"), index: 1 },
        { name: this.$t("moment.monthbirthday"), index: 2 },
        { name: this.$t("moment.monthjoiner"), index: 3 },
        { name: this.$t("moment.monthlService"), index: 4 },
      ],
      topIndex: 0,
      serviceText: "FIVE",

      pageDetailHide: true,
      pageFirstVisit: true,
      refreshStatus: false,
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.login.lang,
    }),
    noMore() {
      return this.query.current >= this.totalPage;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  mounted() {
    if (this.$route.path.indexOf("memberDetail") != -1) {
      this.pageDetailHide = false;
    } else {
      this.pageDetailHide = true;

      if (this.pageFirstVisit) {
        this.pageFirstVisit = false;
        this.refreshStatus = true;

        this.initNew();
      }
    }
  },
  watch: {
    "$route.path"(val) {
      if (val.indexOf("memberDetail") != -1) {
        this.pageDetailHide = false;
      } else {
        this.pageDetailHide = true;

        if (this.pageFirstVisit) {
          this.pageFirstVisit = false;
          this.refreshStatus = true;

          this.initNew();
        }
      }
    },
  },
  methods: {
    ...mapActions({
      getPromote: "moment/getPromote",
      getReturn: "moment/getReturn",
      getBirthday: "moment/getBirthday",
      getCurrent: "moment/getCurrent",
      getService: "moment/getService",
    }),
    initNew() {
      if (this.$route.query.keyWord) {
        this.query.keywords = this.$route.query.keyWord;
      }
      this.getList();
    },
    onTop(val) {
      this.topIndex = val;
      this.getList(1);
    },
    load() {
      this.query.current++;
      this.getList();
    },
    async getList(val) {
      if (val) {
        this.query.current = 1;
        this.pageList = [];
      }
      this.loading = true;
      let params = { ...this.query };
      let res = {};
      if (this.topIndex == 0) {
        res = await this.getPromote(params);
      }
      if (this.topIndex == 1) {
        res = await this.getReturn(params);
      }
      if (this.topIndex == 2) {
        res = await this.getBirthday(params);
      }
      if (this.topIndex == 3) {
        res = await this.getCurrent(params);
      }
      if (this.topIndex == 4) {
        params.anniversaryEnum = this.serviceText;
        res = await this.getService(params);
      }
      let datas = res.data.records;
      this.pageList = [...this.pageList, ...datas];
      this.current = res.data.current;
      this.total = res.data.total;
      this.totalPage = parseInt(this.total / this.query.size);
      let num = this.total % this.query.size;
      if (num > 0) {
        this.totalPage += 1;
      }

      this.loading = false;
    },
    searchFn(data) {
      this.query.keywords = data.searchName;
      this.getList(1);
    },
    onTab(val) {
      this.tabIndex = val;
      let text = "";
      switch (val) {
        case 0:
          text = "FIVE";
          break;
        case 1:
          text = "TEN";
          break;
        case 2:
          text = "FIFTEEN";
          break;
        default:
          text = "FIVE";
          break;
      }
      this.serviceText = text;
      this.getList(1);
    },
    onDetail(val) {
      let sercetCd = this.utils.encryption(val);
      this.$router.push({
        path: "/moment/memberDetail",
        query: { id: sercetCd },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";
@media screen and (max-width: 1600px) {
  .moment {
    .headers {
      .edp-header--search__content {
        box-shadow: 0 -0.0205rem 0.13324rem #fff;
      }
    }
    .top-tabs {
      display: flex;
      align-items: flex-end;
      width: 100%;
      height: toPad(45);
      padding: toPad(10) toPad(40);
      .tabs-item {
        margin-right: toPad(20);
        color: rgba(0, 0, 0, 0.7);
        &.active {
          color: #000;
          font-size: toPad(26);
          font-weight: 600;
        }
        .tips {
          width: toPad(16);
          height: toPad(16);
          margin-left: toPad(6);
          cursor: pointer;
        }
      }
    }
    .moment-content {
      padding: toPad(15) toPad(40) toPad(20);

      .tab-wrap {
        display: flex;
        align-items: center;
        .tab-item {
          height: toPad(45);
          padding: 0 toPad(16);
          border-radius: toPad(6);
          margin-right: toPad(10);

          .icon-pic {
            width: toPad(16);
            height: toPad(16);
          }

          &.active {
            background: rgba(109, 182, 147, 0.17);
          }
        }
        .split-line {
          width: 1px;
          height: toPad(20);
          margin-right: toPad(10);
          background: rgba(0, 0, 0, 0.3);
        }
      }

      .member-title {
        line-height: toPad(62);
        //margin: toPad(14) 0;
      }

      .member-wrap {
        display: flex;
        flex-wrap: wrap;
        //min-height: 80vh;
        .member-item {
          display: flex;
          width: toPad(408);
          //width: toPad(1000);
          //height: toPad(3000);
          padding: toPad(15);
          margin: toPad(10) toPad(7) 0 0;
          background: #fff;
          box-shadow: 0px 4px 13px rgba(230, 236, 244, 0.6);
          border-radius: 10px;
          &:nth-of-type(3n) {
            margin-right: 0;
          }
          .item-pic {
            width: toPad(80);
            height: toPad(80);
            border-radius: toPad(40);
            margin-right: toPad(20);
          }

          .item-text {
            width: toPad(280);
            .text-one {
              display: flex;
              align-items: center;
              font-weight: 500;
              font-size: toPad(18);
              line-height: toPad(24);
              margin-bottom: toPad(8);
              .name-text {
                display: inline-block;
                max-width: 80%;
              }
              .lable {
                display: inline-flex;
                color: #fff;
                border-radius: toPad(5) 0px;
                background: linear-gradient(
                  90.61deg,
                  #60cfc6 3.65%,
                  #61bfb4 99.54%
                );
                padding: 0 toPad(4);
                text-align: center;
                margin-left: toPad(6);
                font-style: normal;
                border: 1px solid rgba(152, 244, 235, 0.6);
                line-height: toPad(16);

                span {
                  transform: scale(0.9);
                  font-size: toPad(12);
                }
              }
            }

            .text-two {
              margin-bottom: toPad(12);

              .tag {
                padding: 0px toPad(5);
                height: toPad(18);
                line-height: toPad(18);
                margin-right: 4px;
                background: rgba(0, 0, 0, 0.05);
              }
            }

            .text-thr {
              margin-bottom: toPad(2);
              .text-item {
                display: flex;
                width: 100%;
                font-size: toPad(14);
                color: rgba(0, 0, 0, 0.5);

                em {
                  font-style: normal;
                  flex-shrink: 0;
                }
                > span {
                  display: inline-block;
                  flex-basis: 100%;
                  padding-left: toPad(16);
                  color: #000;
                  overflow: hidden;

                  span {
                    display: inline-block;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
              }
            }
          }
        }
      }

      .user-empty {
        margin: toPad(200) auto;
        text-align: center;

        img {
          width: toPad(164);
          height: toPad(142);
        }
      }
      .end-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: toPad(50) auto toPad(20);
        span {
          display: inline-block;
          &.line {
            width: toPad(66);
            height: toPad(1);
            background: rgba(0, 0, 0, 0.21);
          }
          &.txt {
            margin: 0 toPad(20);
            color: rgba(0, 0, 0, 0.3);
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1601px) {
  .moment {
    .headers {
      .edp-header--search__content {
        box-shadow: 0 -0.0205rem 0.13324rem #fff;
      }
    }
    .top-tabs {
      display: flex;
      align-items: flex-end;
      width: 100%;
      height: toPad(45);
      padding: toPad(10) toPad(40);
      .tabs-item {
        margin-right: toPad(20);
        color: rgba(0, 0, 0, 0.7);
        .tips {
          width: toPad(16);
          height: toPad(16);
          margin-left: toPad(6);
          cursor: pointer;
        }
        &.active {
          color: #000;
          font-size: toPad(26);
          font-weight: 600;
        }
      }
    }
    .moment-content {
      padding: toPad(15) toPad(40) toPad(20);

      .tab-wrap {
        display: flex;
        align-items: center;
        .tab-item {
          height: toPad(45);
          padding: 0 toPad(16);
          border-radius: toPad(6);
          margin-right: toPad(10);

          .icon-pic {
            width: toPad(16);
            height: toPad(16);
          }

          &.active {
            background: rgba(109, 182, 147, 0.17);
          }
        }
        .split-line {
          width: 1px;
          height: toPad(20);
          margin-right: toPad(10);
          background: rgba(0, 0, 0, 0.3);
        }
      }

      .member-title {
        line-height: toPad(62);
        //margin: toPad(14) 0;
      }

      .member-wrap {
        display: flex;
        flex-wrap: wrap;
        //min-height: 80vh;
        .member-item {
          display: flex;
          width: toPad(364);
          //height: toPad(134);
          padding: toPad(15);
          margin: toPad(10) toPad(6) 0 0;
          background: #fff;
          box-shadow: 0px 4px 13px rgba(230, 236, 244, 0.6);
          border-radius: 10px;
          &:nth-of-type(4n) {
            margin-right: 0;
          }
          .item-pic {
            width: toPad(80);
            height: toPad(80);
            border-radius: toPad(40);
            margin-right: toPad(20);
          }

          .item-text {
            width: toPad(230);
            .text-one {
              display: flex;
              align-items: center;
              font-weight: 500;
              font-size: toPad(18);
              line-height: toPad(24);
              margin-bottom: toPad(8);
              .name-text {
                display: inline-block;
                max-width: 80%;
              }
              .lable {
                display: inline-flex;
                color: #fff;
                border-radius: toPad(5) 0px;
                background: linear-gradient(
                  90.61deg,
                  #60cfc6 3.65%,
                  #61bfb4 99.54%
                );
                padding: 0 toPad(4);
                text-align: center;
                margin-left: toPad(6);
                font-style: normal;
                border: 1px solid rgba(152, 244, 235, 0.6);
                line-height: toPad(16);

                span {
                  transform: scale(0.9);
                  font-size: toPad(12);
                }
              }
            }

            .text-two {
              margin-bottom: toPad(12);

              .tag {
                padding: 0px toPad(5);
                height: toPad(18);
                line-height: toPad(18);
                margin-right: 4px;
                background: rgba(0, 0, 0, 0.05);
              }
            }

            .text-thr {
              margin-bottom: toPad(2);
              .text-item {
                display: flex;
                width: 100%;
                font-size: toPad(14);
                color: rgba(0, 0, 0, 0.5);

                em {
                  font-style: normal;
                  flex-shrink: 0;
                }
                > span {
                  display: inline-block;
                  flex-basis: 100%;
                  padding-left: toPad(16);
                  color: #000;
                  overflow: hidden;

                  span {
                    display: inline-block;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
              }
            }
          }
        }
      }

      .user-empty {
        margin: toPad(200) auto;
        text-align: center;

        img {
          width: toPad(164);
          height: toPad(142);
        }
      }
      .end-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: toPad(50) auto toPad(20);
        span {
          display: inline-block;
          &.line {
            width: toPad(66);
            height: toPad(1);
            background: rgba(0, 0, 0, 0.21);
          }
          &.txt {
            margin: 0 toPad(20);
            color: rgba(0, 0, 0, 0.3);
          }
        }
      }
    }
  }
}
</style>
<style>
.el-tooltip__popper[x-placement^="left"] .popper__arrow::after {
  border-left-color: #dff2f0 !important;
  border-right-color: #dff2f0 !important;
}

.el-tooltip__popper[x-placement^="left"] .popper__arrow {
  border-left-color: #dff2f0 !important;
  border-right-color: #dff2f0 !important;
}
.el-tooltip__popper[x-placement^="right"] .popper__arrow::after {
  border-left-color: #dff2f0 !important;
  border-right-color: #dff2f0 !important;
}

.el-tooltip__popper[x-placement^="right"] .popper__arrow {
  border-left-color: #dff2f0 !important;
  border-right-color: #dff2f0 !important;
}
.draw_share_atooltip {
  background: #dff2f0 !important;
  color: #000 !important;
}
</style>
